import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Trofeo Alfredo Binda - Comune di Cittiglio",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-19 19:29:09",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21872: {
        teamId: "21872",
        teamUciCode: "FDJ",
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        teamNationCode: "FRA",
      },
      21877: {
        teamId: "21877",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21881: {
        teamId: "21881",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      22011: {
        teamId: "22011",
        teamUciCode: "SDW",
        teamName: "Team SD Worx",
        teamNationCode: "NED",
      },
      22296: {
        teamId: "22296",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      22809: {
        teamId: "22809",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23744: {
        teamId: "23744",
        teamUciCode: "CSR",
        teamName: "CANYON//SRAM Racing",
        teamNationCode: "GER",
      },
      23811: {
        teamId: "23811",
        teamUciCode: "WNT",
        teamName: "Ceratizit-WNT Pro Cycling",
        teamNationCode: "GER",
      },
      24281: {
        teamId: "24281",
        teamUciCode: "NXG",
        teamName: "NXTG by Experza",
        teamNationCode: "NED",
      },
      24283: {
        teamId: "24283",
        teamUciCode: "PHV",
        teamName: "Parkhotel Valkenburg",
        teamNationCode: "NED",
      },
      27874: {
        teamId: "27874",
        teamUciCode: "LIV",
        teamName: "Liv Racing Xstra",
        teamNationCode: "NED",
      },
      27895: {
        teamId: "27895",
        teamUciCode: "JVW",
        teamName: "Team Jumbo-Visma",
        teamNationCode: "NED",
      },
      27926: {
        teamId: "27926",
        teamUciCode: "TIB",
        teamName: "EF Education-TIBCO-SVB",
        teamNationCode: "USA",
      },
      28095: {
        teamId: "28095",
        teamUciCode: "COF",
        teamName: "Cofidis Women Team",
        teamNationCode: "FRA",
      },
      28362: {
        teamId: "28362",
        teamUciCode: "VAI",
        teamName: "Aromitalia Basso Bikes Vaiano",
        teamNationCode: "ITA",
      },
      28433: {
        teamId: "28433",
        teamUciCode: "CGS",
        teamName: "Roland Cogeas Edelweiss Squad",
        teamNationCode: "SUI",
      },
      28538: {
        teamId: "28538",
        teamUciCode: "TOP",
        teamName: "Top Girls Fassa Bortolo",
        teamNationCode: "ITA",
      },
      28562: {
        teamId: "28562",
        teamUciCode: "SBT",
        teamName: "Isolmant-Premac-Vittoria",
        teamNationCode: "ITA",
      },
      28954: {
        teamId: "28954",
        teamUciCode: "VAL",
        teamName: "Valcar-Travel & Service",
        teamNationCode: "ITA",
      },
      28991: {
        teamId: "28991",
        teamUciCode: "UAD",
        teamName: "UAE Team ADQ",
        teamNationCode: "UAE",
      },
      29009: {
        teamId: "29009",
        teamUciCode: "BPK",
        teamName: "BePink",
        teamNationCode: "ITA",
      },
      29297: {
        teamId: "29297",
        teamUciCode: "BTW",
        teamName: "Born To Win G20 Ambedo",
        teamNationCode: "ITA",
      },
      29302: {
        teamId: "29302",
        teamUciCode: "SER",
        teamName: "Servetto-Makhymo-Beltrami TSA",
        teamNationCode: "ITA",
      },
      29305: {
        teamId: "29305",
        teamUciCode: "MDS",
        teamName: "Team Mendelspeck",
        teamNationCode: "ITA",
      },
    },
    riders: {
      96873: {
        id: 96873,
        startno: 165,
        firstName: "Gaia",
        lastName: "Masetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-10-26",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      96719: {
        id: 96719,
        startno: 134,
        firstName: "Pien",
        lastName: "Limpens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-02-24",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      114918: {
        id: 114918,
        startno: 73,
        firstName: "Ayesha",
        lastName: "McGowan",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1987-04-02",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      91260: {
        id: 91260,
        startno: 95,
        firstName: "Paula",
        lastName: "Patino",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-03-29",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      90066: {
        id: 90066,
        startno: 175,
        firstName: "Francesca",
        lastName: "Balducci",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-05-01",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      91054: {
        id: 91054,
        startno: 223,
        firstName: "Greta",
        lastName: "Marturano",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-06-14",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      91960: {
        id: 91960,
        startno: 33,
        firstName: "Ella",
        lastName: "Harris",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-07-18",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      154500: {
        id: 154500,
        startno: 203,
        firstName: "Ainara",
        lastName: "Albert Bosch",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2003-06-21",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      89716: {
        id: 89716,
        startno: 61,
        firstName: "Marta",
        lastName: "Cavalli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-18",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89776: {
        id: 89776,
        startno: 101,
        firstName: "Letizia",
        lastName: "Borghesi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-10-16",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      96910: {
        id: 96910,
        startno: 222,
        firstName: "Iris",
        lastName: "Monticolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-07-15",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      154244: {
        id: 154244,
        startno: 114,
        firstName: "Gulnaz",
        lastName: "Khatuntseva",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1994-04-21",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      100582: {
        id: 100582,
        startno: 154,
        firstName: "Sandra",
        lastName: "Levenez",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1979-07-05",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      89983: {
        id: 89983,
        startno: 123,
        firstName: "Alice Maria",
        lastName: "Arzuffi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89832: {
        id: 89832,
        startno: 6,
        firstName: "Ellen",
        lastName: "Van Dijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-02-11",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      92485: {
        id: 92485,
        startno: 24,
        firstName: "Marlen",
        lastName: "Reusser",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1991-09-20",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89433: {
        id: 89433,
        startno: 84,
        firstName: "Amanda",
        lastName: "Spratt",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1987-09-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89826: {
        id: 89826,
        startno: 55,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      90865: {
        id: 90865,
        startno: 125,
        firstName: "Elena",
        lastName: "Pirrone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-02-21",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89405: {
        id: 89405,
        startno: 23,
        firstName: "Chantal",
        lastName: "van den Broek-Blaak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-10-22",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      93107: {
        id: 93107,
        startno: 215,
        firstName: "Marina",
        lastName: "Ivanuk",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1990-09-10",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      89824: {
        id: 89824,
        startno: 53,
        firstName: "Leah",
        lastName: "Kirchmann",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-06-30",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      96819: {
        id: 96819,
        startno: 181,
        firstName: "Silvia",
        lastName: "Zanardi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-03-03",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      114532: {
        id: 114532,
        startno: 205,
        firstName: "Alice",
        lastName: "Capasso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-12-23",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      154491: {
        id: 154491,
        startno: 162,
        firstName: "Maureen",
        lastName: "Arens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-04-16",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      89503: {
        id: 89503,
        startno: 115,
        firstName: "Olga",
        lastName: "Zabelinskaya",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "1980-05-10",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89554: {
        id: 89554,
        startno: 66,
        firstName: "Cecilie Uttrup",
        lastName: "Ludwig",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-08-23",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      114509: {
        id: 114509,
        startno: 176,
        firstName: "Lara",
        lastName: "Scarselli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-12-10",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      114513: {
        id: 114513,
        startno: 233,
        firstName: "Elisa",
        lastName: "Lugli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-11-22",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      92703: {
        id: 92703,
        startno: 144,
        firstName: "Marta",
        lastName: "Lach",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-05-26",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      96716: {
        id: 96716,
        startno: 52,
        firstName: "L\u00c3\u00a9a",
        lastName: "Curinier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-04-21",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      159870: {
        id: 159870,
        startno: 196,
        firstName: "Tatiana",
        lastName: "Chili",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-07-08",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      97844: {
        id: 97844,
        startno: 213,
        firstName: "Isotta\t",
        lastName: "Barbieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-04-19",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      99938: {
        id: 99938,
        startno: 173,
        firstName: "Inga",
        lastName: "Cesuliene",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1986-02-14",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      89528: {
        id: 89528,
        startno: 45,
        firstName: "Ursa",
        lastName: "Pintar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1985-10-03",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96832: {
        id: 96832,
        startno: 152,
        firstName: "C\u00c3\u00a9drine",
        lastName: "Kerbaol",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-05-15",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      89496: {
        id: 89496,
        startno: 72,
        firstName: "Katia",
        lastName: "Ragusa",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-19",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      176891: {
        id: 176891,
        startno: 15,
        firstName: "Amber",
        lastName: "Kraak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-07-29",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89739: {
        id: 89739,
        startno: 63,
        firstName: "Emilia",
        lastName: "Fahlin",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1988-10-24",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      96036: {
        id: 96036,
        startno: 92,
        firstName: "Sarah",
        lastName: "Gigante",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-10-06",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      154486: {
        id: 154486,
        startno: 51,
        firstName: "Francesca",
        lastName: "Barale",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2003-04-29",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      90862: {
        id: 90862,
        startno: 151,
        firstName: "Martina",
        lastName: "Alzini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-10",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      99107: {
        id: 99107,
        startno: 132,
        firstName: "Anne",
        lastName: "Van Rooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-04",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89801: {
        id: 89801,
        startno: 4,
        firstName: "Leah",
        lastName: "Thomas",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-05-29",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      97208: {
        id: 97208,
        startno: 121,
        firstName: "Olivia",
        lastName: "Baril",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1997-10-10",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89714: {
        id: 89714,
        startno: 2,
        firstName: "Elisa",
        lastName: "Balsamo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-27",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      114517: {
        id: 114517,
        startno: 212,
        firstName: "Chia",
        lastName: "Pedrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-01-08",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      89819: {
        id: 89819,
        startno: 34,
        firstName: "Mikayla",
        lastName: "Harvey",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-09-07",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      91078: {
        id: 91078,
        startno: 93,
        firstName: "Sara",
        lastName: "Mart\u00c3\u00adn",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-03-22",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89489: {
        id: 89489,
        startno: 44,
        firstName: "Erica",
        lastName: "Magnaldi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96862: {
        id: 96862,
        startno: 193,
        firstName: "Silvia",
        lastName: "Magri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-10-17",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      114521: {
        id: 114521,
        startno: 221,
        firstName: "Cristina",
        lastName: "Tonetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-07-06",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      138754: {
        id: 138754,
        startno: 112,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      96734: {
        id: 96734,
        startno: 65,
        firstName: "Marie",
        lastName: "Le Net",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-01-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89719: {
        id: 89719,
        startno: 126,
        firstName: "Silvia",
        lastName: "Persico",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-07-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      90019: {
        id: 90019,
        startno: 36,
        firstName: "Pauliena",
        lastName: "Rooijakkers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-05-12",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      96857: {
        id: 96857,
        startno: 16,
        firstName: "Noemi",
        lastName: "R\u00c3\u00bcegg",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-04-19",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      125733: {
        id: 125733,
        startno: 25,
        firstName: "Anna",
        lastName: "Shackley",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-05-17",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      114539: {
        id: 114539,
        startno: 124,
        firstName: "Eleonora Camilla",
        lastName: "Gasparrini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      114476: {
        id: 114476,
        startno: 5,
        firstName: "Shirin",
        lastName: "van Anrooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-02-05",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      98831: {
        id: 98831,
        startno: 85,
        firstName: "Teniel",
        lastName: "Campbell",
        nationCode: "TRI",
        nationName: "Trinidad & Tobago",
        birthDate: "1997-09-23",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89534: {
        id: 89534,
        startno: 31,
        firstName: "Alena",
        lastName: "Amialiusik",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1989-06-02",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      96875: {
        id: 96875,
        startno: 185,
        firstName: "Matilde",
        lastName: "Vitillo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-03-08",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      89781: {
        id: 89781,
        startno: 202,
        firstName: "Alice",
        lastName: "Gasparini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-12-14",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      110343: {
        id: 110343,
        startno: 136,
        firstName: "Quinty",
        lastName: "Schoens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-02-12",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89843: {
        id: 89843,
        startno: 106,
        firstName: "Lauren",
        lastName: "Stephens",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1986-12-27",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      96748: {
        id: 96748,
        startno: 183,
        firstName: "Marketa",
        lastName: "Hajkova",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "2000-03-14",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      96820: {
        id: 96820,
        startno: 174,
        firstName: "Sofia",
        lastName: "Collinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-08-24",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      89932: {
        id: 89932,
        startno: 232,
        firstName: "Vania",
        lastName: "Canvelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-10-21",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      95291: {
        id: 95291,
        startno: 74,
        firstName: "Marta",
        lastName: "Jaskulska",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "2000-03-25",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      125411: {
        id: 125411,
        startno: 111,
        firstName: "Ines",
        lastName: "Cantera Carrasco",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2002-07-30",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      156229: {
        id: 156229,
        startno: 191,
        firstName: "Anna",
        lastName: "Chili",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-07-08",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      89703: {
        id: 89703,
        startno: 204,
        firstName: "Beatrice",
        lastName: "Rossato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-11-06",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      157545: {
        id: 157545,
        startno: 225,
        firstName: "Vanessa",
        lastName: "Michieletto",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-05",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      89533: {
        id: 89533,
        startno: 82,
        firstName: "Urska",
        lastName: "Zigart",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1996-12-04",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89736: {
        id: 89736,
        startno: 3,
        firstName: "Audrey",
        lastName: "Cordon-Ragot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-22",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89537: {
        id: 89537,
        startno: 22,
        firstName: "Elena",
        lastName: "Cecchini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-25",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      96741: {
        id: 96741,
        startno: 133,
        firstName: "Femke",
        lastName: "Gerritse",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-05-14",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      159727: {
        id: 159727,
        startno: 116,
        firstName: "Petra",
        lastName: "Stiasny",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-09-10",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89431: {
        id: 89431,
        startno: 155,
        firstName: "Rachel",
        lastName: "Neylan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1982-03-09",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      89839: {
        id: 89839,
        startno: 103,
        firstName: "Kathrin",
        lastName: "Hammes",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-09",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89484: {
        id: 89484,
        startno: 146,
        firstName: "Lara",
        lastName: "Vieceli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-07-16",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      96745: {
        id: 96745,
        startno: 131,
        firstName: "Rosalie",
        lastName: "Van Der Wolf",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-03-11",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89828: {
        id: 89828,
        startno: 11,
        firstName: "Coryn",
        lastName: "Labecki",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-08-26",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      106424: {
        id: 106424,
        startno: 105,
        firstName: "Magdeleine",
        lastName: "Vallieres Mill",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "2001-08-10",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89745: {
        id: 89745,
        startno: 1,
        firstName: "Elisa",
        lastName: "Longo Borghini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-12-10",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      156302: {
        id: 156302,
        startno: 211,
        firstName: "Silvia",
        lastName: "Bortolotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2003-05-27",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      89725: {
        id: 89725,
        startno: 186,
        firstName: "Michaela",
        lastName: "Drummond",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-04-05",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      90596: {
        id: 90596,
        startno: 42,
        firstName: "Mavi",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-01-02",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89470: {
        id: 89470,
        startno: 41,
        firstName: "Sofia",
        lastName: "Bertizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-08-21",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      177979: {
        id: 177979,
        startno: 236,
        firstName: "Alessia",
        lastName: "Missiaggia",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-01-29",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      168313: {
        id: 168313,
        startno: 46,
        firstName: "Alena",
        lastName: "Ivanchenko",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "2003-11-16",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89925: {
        id: 89925,
        startno: 64,
        firstName: "Victorie",
        lastName: "Guilman",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-03-25",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      96893: {
        id: 96893,
        startno: 192,
        firstName: "Giulia",
        lastName: "Luciani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-01-14",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      89790: {
        id: 89790,
        startno: 235,
        firstName: "Francesca",
        lastName: "Pisciali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-05-19",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      89827: {
        id: 89827,
        startno: 56,
        firstName: "Floortje",
        lastName: "Mackaij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-10-18",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      92095: {
        id: 92095,
        startno: 32,
        firstName: "Elise",
        lastName: "Chabbey",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-04-24",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      93655: {
        id: 93655,
        startno: 14,
        firstName: "Anna",
        lastName: "Henderson",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-11-14",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      96864: {
        id: 96864,
        startno: 122,
        firstName: "Federica",
        lastName: "Piergiovanni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-07-30",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      90020: {
        id: 90020,
        startno: 12,
        firstName: "Aafke",
        lastName: "Soet",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-11-23",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      96746: {
        id: 96746,
        startno: 164,
        firstName: "Julia",
        lastName: "Borgstr\u00c3\u00b6m",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-06-09",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      96756: {
        id: 96756,
        startno: 161,
        firstName: "Amber",
        lastName: "Aernouts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-10-25",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      89702: {
        id: 89702,
        startno: 182,
        firstName: "Nadia",
        lastName: "Quagliotto",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-03-22",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      96892: {
        id: 96892,
        startno: 201,
        firstName: "Emanuela",
        lastName: "Zanetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-03-13",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      96773: {
        id: 96773,
        startno: 135,
        firstName: "Julia",
        lastName: "Van Bokhoven",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-04-01",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89596: {
        id: 89596,
        startno: 102,
        firstName: "Krista",
        lastName: "Doebel-Hickok",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-04-28",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89480: {
        id: 89480,
        startno: 96,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      97814: {
        id: 97814,
        startno: 172,
        firstName: "Francesca",
        lastName: "Baroni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-04",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      156228: {
        id: 156228,
        startno: 231,
        firstName: "Eva Maria",
        lastName: "Gatscher",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-04-05",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      92102: {
        id: 92102,
        startno: 26,
        firstName: "Niamh",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2000-08-12",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89514: {
        id: 89514,
        startno: 94,
        firstName: "Lourdes",
        lastName: "Oyarbide",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-04-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      90853: {
        id: 90853,
        startno: 194,
        firstName: "Sara",
        lastName: "Casasola",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-29",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      89984: {
        id: 89984,
        startno: 143,
        firstName: "Maria Giulia",
        lastName: "Confalonieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      90890: {
        id: 90890,
        startno: 224,
        firstName: "Alessia",
        lastName: "Vigilia",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-09-01",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      98823: {
        id: 98823,
        startno: 86,
        firstName: "Chelsie Wei Shi",
        lastName: "Tan",
        nationCode: "SIN",
        nationName: "Singapore",
        birthDate: "1990-01-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      92687: {
        id: 92687,
        startno: 142,
        firstName: "Laura",
        lastName: "Asencio",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-14",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      96909: {
        id: 96909,
        startno: 226,
        firstName: "Giorgia",
        lastName: "Vettorello",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-07-06",
        teamId: 28538,
        teamName: "Top Girls Fassa Bortolo",
        stillInTheRace: "Y",
      },
      89422: {
        id: 89422,
        startno: 71,
        firstName: "Jeanne",
        lastName: "Korevaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-09-24",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      186806: {
        id: 186806,
        startno: 113,
        firstName: "L\u00c3\u00a9a",
        lastName: "Stern",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-09-25",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      91959: {
        id: 91959,
        startno: 62,
        firstName: "Brodie",
        lastName: "Chapman",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89552: {
        id: 89552,
        startno: 153,
        firstName: "Clara",
        lastName: "Koppenburg",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1995-08-03",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      96861: {
        id: 96861,
        startno: 141,
        firstName: "Camilla",
        lastName: "Alessio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-07-23",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      96753: {
        id: 96753,
        startno: 75,
        firstName: "Silke",
        lastName: "Smulders",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-04-01",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89588: {
        id: 89588,
        startno: 216,
        firstName: "Viktoriia",
        lastName: "Melnychuk",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1998-06-02",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      90063: {
        id: 90063,
        startno: 104,
        firstName: "Sara",
        lastName: "Poidevin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1996-05-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89448: {
        id: 89448,
        startno: 35,
        firstName: "Soraya",
        lastName: "Paladin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      156230: {
        id: 156230,
        startno: 206,
        firstName: "Asia",
        lastName: "Zontone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-11-04",
        teamId: 28562,
        teamName: "Isolmant-Premac-Vittoria",
        stillInTheRace: "Y",
      },
      89950: {
        id: 89950,
        startno: 91,
        firstName: "Katrine",
        lastName: "Aalerud",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-04",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89450: {
        id: 89450,
        startno: 81,
        firstName: "Ane",
        lastName: "Santesteban",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-12-12",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89471: {
        id: 89471,
        startno: 83,
        firstName: "Arianna",
        lastName: "Fidanza",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-01-06",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      114527: {
        id: 114527,
        startno: 234,
        firstName: "Angela",
        lastName: "Oro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-05-27",
        teamId: 29305,
        teamName: "Team Mendelspeck",
        stillInTheRace: "Y",
      },
      89555: {
        id: 89555,
        startno: 21,
        firstName: "Ashleigh",
        lastName: "Moolman-Pasio",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1985-12-09",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89825: {
        id: 89825,
        startno: 54,
        firstName: "Juliette",
        lastName: "Labous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-04",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      157566: {
        id: 157566,
        startno: 195,
        firstName: "Sofia",
        lastName: "Clerici",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-11-03",
        teamId: 29297,
        teamName: "Born To Win G20 Ambedo",
        stillInTheRace: "Y",
      },
      97845: {
        id: 97845,
        startno: 214,
        firstName: "Sofia",
        lastName: "Barbieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-06-21",
        teamId: 29302,
        teamName: "Servetto-Makhymo-Beltrami TSA",
        stillInTheRace: "Y",
      },
      89463: {
        id: 89463,
        startno: 171,
        firstName: "Rasa",
        lastName: "Leleivyte",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1988-07-22",
        teamId: 28362,
        teamName: "Aromitalia Basso Bikes Vaiano",
        stillInTheRace: "Y",
      },
      89523: {
        id: 89523,
        startno: 43,
        firstName: "Eugenia",
        lastName: "Bujak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-06-25",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      167331: {
        id: 167331,
        startno: 156,
        firstName: "Olivia",
        lastName: "Onesti",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2003-12-06",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      89595: {
        id: 89595,
        startno: 76,
        firstName: "Rachele",
        lastName: "Barbieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-21",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      90025: {
        id: 90025,
        startno: 13,
        firstName: "Karlijn",
        lastName: "Swinkels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-10-28",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89527: {
        id: 89527,
        startno: 145,
        firstName: "Hanna",
        lastName: "Nilsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1992-02-16",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      166448: {
        id: 166448,
        startno: 163,
        firstName: "Cassia",
        lastName: "Boglio",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-07-01",
        teamId: 24281,
        teamName: "NXTG by Experza",
        stillInTheRace: "Y",
      },
      181262: {
        id: 181262,
        startno: 184,
        firstName: "Letizia",
        lastName: "Brufani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-02-23",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Trofeo Alfredo Binda"
  const raceID = 9046

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
